import React, { Suspense } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import NumberFormat from "react-number-format";
//import ShippingOptionForm from "./ShippingOptionForm";
//import PaymentOptionForm from "./PaymentOptionForm";
//import CreditDetailsForm from "./CreditDetailsForm";
//import StripeForm from "./CreditDetailsForm/StripeForm";
//import SummaryForm from "./Summary";
import APILoader from 'Components/MainScreen/AppBody/APILoader';
// import ValidSMSModal from 'Components/MainScreen/AppBody/ValidSMSModal';
// import MakeOrderErrorModal from "./MakeOrderErrorModal";
// import ThanksModal from "../ThanksModal";
// import ThanksCouponModal from "../ThanksCouponModal";
// import CardNumberModal from "./CardNumberModal";
// import MapModal from "./MapModal";
// import ReactPixel from 'react-facebook-pixel';
const ShippingOptionForm = React.lazy(() => import('./ShippingOptionForm'));
const SummaryForm = React.lazy(() => import('./Summary'));
const StripeForm = React.lazy(() => import('./CreditDetailsForm/StripeForm'));
const CreditDetailsForm = React.lazy(() => import('./CreditDetailsForm'));
const PaymentOptionForm = React.lazy(() => import('./PaymentOptionForm'));
//const APILoader = React.lazy(() => import('Components/MainScreen/AppBody/APILoader'));
const ValidSMSModal = React.lazy(() => import('Components/MainScreen/AppBody/ValidSMSModal'));
const MakeOrderErrorModal = React.lazy(() => import('./MakeOrderErrorModal'));
const ThanksModal = React.lazy(() => import('../ThanksModal'));
const ThanksCouponModal = React.lazy(() => import('../ThanksCouponModal'));
const CardNumberModal = React.lazy(() => import('./CardNumberModal'));
const MapModal = React.lazy(() => import('./MapModal'));
const ReactPixel = React.lazy(() => import('react-facebook-pixel'));
@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class Payment extends React.Component {
    constructor(props) {
        super(props)
        const { orderData, otherPaymentType, paymentProcessing } = this.props.myShopApiStore;
        
        this.state = {
            isShippingOption: !orderData || orderData.isDelivery,
            currentStep:0, // paymentProcessing ? this.checkoutProcess.findIndex(step => step.name === 'summary') : 0,
           //  isPaymentForm: false
           smsModalIsOpen: false,
           thanksModalIsOpen: false,
           termsModalIsOpen: false,
           apiLoaderIsOpen: false,
           makeOrderErrorModalIsOpen: false,
           makeOrderErrorModal: { code: '', message: '' },
           cardNumberModalIsOpen: false,
           mapModalIsOpen: false,
           stepsHistory: [],
           orderStatus: 'init', // success|failure
       }
    }

    checkoutProcess = [
        { title: 'Shipping Option', name: 'shipping', view: ShippingOptionForm, nextName: () => 'payment_options' },
        { title: 'Payment Option', name: 'payment_options', view: PaymentOptionForm, nextName: async () => 
              !await this.isUserRegistred() || this.props.isWrongSMSCode ? 'payment_options' : (this.state.isShippingOption ? 'location': 'summary') 
        },
        { title: 'Approve location', name: 'location', modal: 'mapModalIsOpen', nextName: () => 'summary'},
        { title: 'Summary', name: 'summary', view: SummaryForm, 
            nextName: async () => {
                const { paymentProcessing, orderData, otherPaymentType, shop } = this.props.myShopApiStore;
                
                switch (true) {
                    case this.state.orderStatus === 'success':
                        this.stopProcessing();
                        return null;
                    case !paymentProcessing && (new URLSearchParams(window.location.search)).get('payment_intent'):
                        this.stopProcessing();
                        return null;
                    case this.props.isWrongSMSCode:
                        return 'payment_options';
                    case orderData.isCredit && !paymentProcessing:
                        return !(await this.isPreOrderCreated()) ? 'summary' :
                            (shop.paymentSystem === 'stripe' ? 'stripe' : 'credit')
                    case !!otherPaymentType && !paymentProcessing:
                        return 'cardNumber';
                    default:
                        return !(await this.isMakeOrderSuccess()) ? 'summary' : 'summary';
                }
            }
        },
        { title: 'Card Number', name: 'cardNumber', modal: 'cardNumberModalIsOpen', nextName: () => 'summary' },
        { title: 'Credit Details', name: 'credit', view: CreditDetailsForm, 
            nextName: async () => !(await this.isMakeOrderSuccess()) ? 'summary' : 'summary' 
        },
        { title: 'Stripe', name: 'stripe', view: StripeForm, nextName: () => 'summary'}
    ];

    stopProcessing = () => {
        const { setPaymentProcessing, setCreditParams, setCreditUniqueId, clearReadyToOrderCartItems,
            clearBlockClosing, clearCoupon } = this.props.myShopApiStore;
        setPaymentProcessing(false);
        setCreditParams('token', '');
        setCreditUniqueId('');
        const search = new URLSearchParams(window.location.search);
        search.has('payment_intent') && window.history.replaceState(null, '', window.location.pathname);
        
        this.props.ifaceMngr.setActiveTopMenu('ShopMenu');
        if (this.state.orderStatus === 'success') {
            clearReadyToOrderCartItems();
            clearBlockClosing();
            clearCoupon();
        }
    }

    tryAnotherCard = (message = 'Payment failed. Try another card.') => {
        this.props.myShopApiStore.setPaymentProcessing(false);
        this.setState(prevState => ({ ...prevState, currentStep: this.checkoutProcess.findIndex(step => step.name === 'summary')}));
        if (message) this.setState({makeOrderErrorModalIsOpen: true, makeOrderErrorModal: { code: 'PAYMENT_FAILED', message }});
    }

    nextStep = () => {
        
        const defaultNext = async () => {
            const nextName = await this.checkoutProcess[this.state.currentStep].nextName();

            if (!nextName) {
                this.stopProcessing();
                return;
            }
                

            const nextStep = this.checkoutProcess.findIndex(step => step.name === nextName);
            const modal = this.checkoutProcess[nextStep].modal ?? false;
            this.checkoutProcess[nextStep].view && this.setState(prevState => ({...prevState, stepsHistory: [...prevState.stepsHistory, prevState.currentStep]}));
            this.setState(prevState => ({ ...prevState, currentStep: nextStep , ...( modal ? {[modal]: true }:{}) }));
        }
                
        this.checkoutProcess[this.state.currentStep].nextStep && this.checkoutProcess[this.state.currentStep].nextStep();
        defaultNext();
    }

    prevStep = () => {
        if(this.state.currentStep > 0){
            const history = [...this.state.stepsHistory];
            const prevStep = history.pop();
            if (typeof prevStep === 'undefined' || prevStep < 0 || prevStep > this.checkoutProcess.length - 1) {
                window.history.replaceState(null, document.title, window.location.pathname);
                return;
            }
            this.setState(prevState => ({...prevState, currentStep: prevStep , stepsHistory: history}));

            //reset date,from,to of deliveryData & pickupData---------------
            if(this.checkoutProcess[prevStep].name === 'shipping'){
                const { orderData, setOrderData } = this.props.myShopApiStore;
                const { deliveryData, pickupData } = orderData;
                setOrderData('deliveryData', {...deliveryData, date: '', from: '', to: ''})
                setOrderData('pickupData', {...pickupData, date: '', from: '', to: ''})
            }
            //-----
            
        }
    }

    componentDidMount = () => {
        const { paymentProcessing, creditParams } = this.props.myShopApiStore;
        if (paymentProcessing) {
            this.props.myShopApiStore.setOtherPaymentType('')
            this.props.myShopApiStore.setCardNumber('')
            
            const search = new URLSearchParams(window.location.search);

            if (creditParams.token !== search.get('payment_intent_client_secret')) {
                this.setState(prevState => ({ ...prevState, orderStatus: 'failure' }));
                this.stopProcessing();
                return;
            }
            
            this.setState(prevState => ({ ...prevState, currentStep: this.checkoutProcess.findIndex(step => step.name === 'summary')}));
            (async () => search.get('redirect_status') !== 'succeeded' ? 'summary' :
                (await this.checkoutProcess.find(step => step.name === 'summary').nextName())
            )()
                .then((stepName) =>        
                    this.setState(prevState => ({...prevState, 
                        currentStep: this.checkoutProcess.findIndex(step => step.name === stepName)}))
                );
           
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { orderData } = this.props.myShopApiStore;
        const isShippingOption = !orderData || orderData.isDelivery || orderData.orderType === 'delivery';

        if(this.state.isShippingOption !== isShippingOption){
            this.setState({...prevState, isShippingOption})
        }

        if (this.state.orderStatus === 'success' && prevState.orderStatus !== 'success') {
            this.setState(prevState => ({ ...prevState, thanksModalIsOpen: true }));
        }
    }

    isUserRegistred = async () => {
        const { registerUser } = this.props.myShopApiStore;
        this.setState({apiLoaderIsOpen: true});
        const resp = await registerUser();
        this.setState({apiLoaderIsOpen: false});
        !resp?.data && this.setState(prevState => ({ ...prevState, smsModalIsOpen: true }))
        return !!resp?.data;
    }
   
    isPreOrderCreated = async () => {
        const { createSessionResp, makePreorderResp, creditUniqueId, shop} = this.props.myShopApiStore;

        this.setState({apiLoaderIsOpen: true});
        const resp = await makePreorderResp();
        this.setState({apiLoaderIsOpen: false});


        switch (true) {
            case !resp.data || resp.data.errorCode !== '0':
                this.setState({makeOrderErrorModalIsOpen: true, makeOrderErrorModal: { code: resp.data?.errorCode, message: resp.data?.errorMessage}})
                return false;
            case shop.paymentSystem === 'stripe' && !this.props.myShopApiStore.creditParams.token:
                this.setState({makeOrderErrorModalIsOpen: true, makeOrderErrorModal: { code: 'STRIPE_ERROR', message: 'Server error!'}})
                return false;
            default:
                if(![ 'stripe', 'pelecard' ].includes( shop.paymentSystem) && !['comax'].includes(shop.externalTypeCodename)) {
                    await createSessionResp(creditUniqueId); //only for zcredit
                }
                return true;
        }
    }

    isPreOrderUsed = async (tries) => {
        if (tries <= 0) return false;
        return (await this.props.myShopApiStore.checkPreorderResp()).data.errorCode === '0' ||
                (new Promise(resolve => setTimeout(() => resolve(), 3000)))
                    .then(()=> this.isPreOrderUsed(tries-1));
    }

    isMakeOrderSuccess = async () => {
        const { makeOrderResp, orderData, shop } = this.props.myShopApiStore;
       
        this.setState({apiLoaderIsOpen: true});
        const tries = 45;
        const resp = !orderData.isCredit || shop.paymentSystem !== 'stripe' || (await this.isPreOrderUsed(tries)) 
            ? await makeOrderResp() 
            : { 
                data: {
                    errorCode: 'PaymentError',
                    errorMessage: 'Payment is pending. Try reload the page or click continue one more time later.'
                }
            };

        this.setState({apiLoaderIsOpen: false});

        if (!resp.data || resp.data.errorCode !== '0'){
            this.setState({
                makeOrderErrorModalIsOpen: true, 
                makeOrderErrorModal: {code: resp.data?.errorCode, message: resp.data?.errorMessage},
                orderStatus: 'failure'
            })
            return false;
        }
        else {
            this.setState({orderStatus: 'success'});
            if (process.env.REACT_APP_FACEBOOK_PIXEL) ReactPixel.track('Purchase');
        }

        return true;
    }

    render() {
        const { shopData, mainColor, shop, cartPrice, hasPostCoupon, orderData } = this.props.myShopApiStore;   
        const { currencyName } = shopData.country;
        const { t } = this.props;

        const View = this.checkoutProcess
            .filter((step, i) => step.view && i <= this.state.currentStep )
            .pop().view;  
        return (
            <div className={style.Container}>
                {
                    !shop.isBusiness &&
                        <div className={style.PriceInfo} style={{backgroundColor: mainColor}}>
                            <div className={style.PriceTitle}>
                                {t(this.checkoutProcess[this.state.currentStep].name === 'summary' ? 'total amount' :'Items subtotal' ) + ' '}
                                <NumberFormat
                                    value={ cartPrice }
                                    prefix={ currencyName }
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType={"text"}
                                />
                            </div>
                            <div className={style.PriceDescription}> 
                                { (this.checkoutProcess[this.state.currentStep].name === 'summary' 
                                    ? t('including taxes (if applicable)') 
                                    : shopData.paymentDescription) + ' ' } 
                                <NumberFormat
                                    value={ cartPrice + (orderData.taxes ?? 0) }
                                    prefix={ currencyName }
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType={"text"}
                                />
                            </div>
                        </div>
                }
                <Suspense fallback={<div>Loading</div>}>
                    <View  setShippingOption={ v => this.setState({isShippingOption: v}) }
                        prevStep={this.prevStep}
                        currentStep={this.state.currentStep}
                        nextStep={this.nextStep}
                        openBasket={this.props.openBasket}
                        showLoader = { open => this.setState({apiLoaderIsOpen: open})}
                        showError = {(code, message) => this.setState({makeOrderErrorModalIsOpen: true, makeOrderErrorModal: { code, message}})}
                        tryAnotherCard = {this.tryAnotherCard}
                    />
                </Suspense>
                <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                <ValidSMSModal modalIsOpen={this.state.smsModalIsOpen} 
                    closeModal={() => this.setState({smsModalIsOpen: false})}
                    handleMakeOrderCall={this.handleMakeOrderCall} action='Delivery' nextStep={this.nextStep}
                />
                
                <CardNumberModal closeModal={() => this.setState({ cardNumberModalIsOpen: false })}
                    modalIsOpen={ this.state.cardNumberModalIsOpen }
                    nextStep={ this.nextStep }
                    handleSendClick={ () => {
                        this.props.myShopApiStore.setOrderData("isCredit", false);
                        this.props.myShopApiStore.setPaymentProcessing(true);
                        this.checkoutProcess.find(step => step.name === 'summary').nextName()
                    }}
                />
               
                <MapModal openBasket={this.props.openBasket} 
                    closeModal={() => {
                        this.setState({mapModalIsOpen: false}); 
                        //this.prevStep();
                    }} 
                    modalIsOpen={this.state.mapModalIsOpen} 
                    prevStep={this.prevStep}
                    nextStep={this.nextStep}
                    showLoader={(open) => this.setState({apiLoaderIsOpen: open})}
                    apiLoaderIsOpen={this.state.apiLoaderIsOpen}
                />
                {
                    hasPostCoupon
                    ?
                        <ThanksCouponModal closeModal={() => {
                            this.setState({thanksModalIsOpen: false})
                            this.stopProcessing();
                        }} modalIsOpen={this.state.thanksModalIsOpen} />
                    :
                        <ThanksModal closeModal={() => {
                            this.setState({thanksModalIsOpen: false})
                            this.stopProcessing();
                        }} modalIsOpen={this.state.thanksModalIsOpen} />
                }
                <MakeOrderErrorModal 
                    modalGoBack={false}
                    closeModal={() => {
                            this.setState({makeOrderErrorModalIsOpen: false, makeOrderErrorModal: { code: '', message: ''}})
                            this.stopProcessing();
                            //this.nextStep()
                        }
                    } 
                    modalIsOpen={this.state.makeOrderErrorModalIsOpen} 
                    errorCode={this.state.makeOrderErrorModal.code} 
                    errorMessage={this.state.makeOrderErrorModal.message}
                    openBasket={this.props.openBasket}
                />
            </div>
        )
    }
}

export default withTranslation()(Payment);
